<template>
  <div class="page"
    style="max-width: 600px; overflow: auto; margin: 0 auto;height: 100%;background-size: 100% 100% ; background-image: url(./img/wj07.jpg);"
    >
  <div style="height:20px"></div>
  

      <div class="tm" @click="goIvst(item)" v-for="(item,idx) in tempList" :key="idx">
        <div class="wjitem " >
          <div class="bolder">
            {{item[0].plan_name}}
          </div>
          <div class="wjdesc">
            {{item[0].sta_time}} 至 {{item[0].end_time}}
          </div>
          <div class="wjdesc" style="padding-bottom:0" v-for="(ivst,i) in item" :key="i">
            {{ivst.ivst_title}}
            <span class="joined" v-if="ivst.joined">已参与</span>
            <span class="nojoin" v-else>未参与</span>
          </div>

          <div class="righticon">
              <img :src="'./img/right.png'">
            </div>

        </div>

      </div>

      <div v-if="tempList.length==0" style="text-align:center; line-height:200px">暂无问卷~</div>
    </div>


</template>

<script>
export default {
  data() {
    return {
      plan_id: 0,
      ivst_id: 0,
      planInfo: null,
      IvstInfo: null,
      tempList: [],
      color: ["1fa4fb", "3e6bff", "139efc", "149efc", "38aaf5", "2ea7f9", "3f6cfe", "109ffe", "4375ff"],
      tm0List: [],
      ckeckedXX: 0,
      step: 0,
      joined: false,
      open:0
    }
  },
  mounted() {

    
    if(this.$Request("openid")){
      sessionStorage.setItem("ivst_stu_id",this.$Request("openid"))
      this.open = 1
    }
    if(this.$Request("pmid")){
      sessionStorage.setItem("ivst_pmid",this.$Request("pmid"))
    }
    this.getPlanInfo()
  },
  methods: {
    getPlanInfo() {
      this.$http.post("/api/app_find_plan_list", {
        uid:sessionStorage.getItem("ivst_stu_id"),
        pmid: sessionStorage.getItem("ivst_pmid"),
         utype:0,
         open:this.open

      }).then(res => {
        
        this.tempList = res.data
      })
    },
    goIvst(item){
      this.$router.push("/h5_ivst_plan?pid="+item[0].plan_id)
    }
   


  },
}
</script>

<style>
.tm {
  border: 3px solid #0f9fff;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 3px 1px 1px #2226bc;
  position: relative;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10px;

}

.stitem {
  width: 45%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 2px solid #1fa4fb;
  background-color: #1fa4fb;
}

.stitemckd {
  border: 2px solid #f9b379;
}

.stitem:nth-child(2n+1) {
  margin-right: 10%;
}

.bolder {
  font-weight: bolder;
}

.wjitem {
  border-bottom: 1px solid #eee;
  font-size: 14px;
  padding: 10px 15px;
  position: relative;
  border-radius: 10px;
}

.wjdesc {
  font-size: 12px;
  padding: 10px 0;
}

.wjstatus {
  /*float: right;*/
  margin-right: 10px;
  vertical-align: middle;
}

.wjtitle {
  margin-right: 30px;
}

.wjitem:active {
  background-color: #eee;
}

.righticon {
  width: 10px;
  height: 15px;
  position: absolute;
  top: 25px;
  right: 10px;
}

.righticon img {
  width: 100%;
  height: 100%;
}

.wjitem:nth-child(1) {
  border-top: 1px solid #eee;
}
.joined{ color: #0f9fff;}
.nojoin{ color: orangered;}

</style>
